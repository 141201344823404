<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="5">
        <h1 style="text-align: center ">Treasury</h1>
        <p class="text-muted" style="text-align:center">Register Macadress</p><br>
          <b-card-group>
            <b-card no-body class="p-2">
              <b-card-body>
                <b-form @submit.prevent="login">
                  <b-input-group class="mb-2">
                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="text"
                                  class="form-control"
                                  id="address"
                                  name="address"
                                  placeholder="Macadress"
                                  autocomplete="address"
                                  v-model="address" />
                  </b-input-group>
                  <b-row class="justify-content-center">
                    <b-col cols="3">
                      <b-button variant="primary" class="px-4" type="submit">Save</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group><br>
        <p class="text-muted" style="text-align:center">Treasury - Admin Area</p>
        <p class="text-muted" style="text-align:center">&copy; 2019</p><br>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Macaddress',
  data() {
    return {
      address: '',
    }
  },
  created() {
      if (this.trueMac) {
          this.$router.push({ name: 'Login' })
      }
  },
  computed: {
      ...mapGetters(['trueMac']),
      ...mapState(['errors'])
  },
  methods: {
    login() {
      if (this.address === '') {
        return this.$toasted.error('Macaddress is required!')
      }
      this.$store.dispatch('retrieveMac', {
        address: this.address
      })
      .then(() => {
        this.$router.push({name: 'Login'})
        this.$toasted.success('Macadress Successfully registed!')
      })
    }
  },
}
</script>
